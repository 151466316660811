:root {
    --crsp-sky-primary-color: rgb(127, 204, 223);
    --crsp-sky-secondary-color: white;
    --crsp-sky-tertiary-color: rgb(200, 200, 200);

    --lght-olv-primary-color: #88A47C;
    --lght-olv-secondary-color: white;
    --lght-olv-tertiary-color: rgb(200, 200, 200);

    --hllwn-primary-color: #202123;
    --hllwn-secondary-color: #DC5F00;
    --hllwn-tertiary-color: rgb(150, 100, 0);

    --yllw-wtrmln-primary-color: #fff68f;
    --yllw-wtrmln-secondary-color: black;
    --yllw-wtrmln-tertiary-color: rgb(25, 25, 25);

    --darker-primary-color: rgb(10, 10, 10);
    --darker-secondary-color: #aaaaaa;
    --darker-tertiary-color: #777777;

    --light-primary-color: #e2e2e2;
    --light-secondary-color: black;
    --light-tertiary-color: rgb(25, 25, 25);

    --dark-primary-color: #0e1317;
    --dark-secondary-color: white;
    --dark-tertiary-color: rgb(200, 200, 200);

    --blue-primary-color: #5DA7DB;
    --blue-secondary-color: white;
    --blue-tertiary-color: rgb(200, 200, 200);

    --pink-primary-color: #F2D7D9;
    --pink-secondary-color: #7D1E6A;
    --pink-tertiary-color: #601851;
}


/* Light: light */
.light-sheet,
.light-sheet-dditem,
.light-sheet textarea::-webkit-input-placeholder {
background-color: var(--light-primary-color) !important;
color: var(--light-secondary-color) !important;
caret-color: var(--light-secondary-color);
}

.light-sheet-select::selection {
color: var(--light-primary-color);
background-color: var(--light-secondary-color);
}

.light-sheet-ddmenu,
.light-sheet-thumb::-webkit-scrollbar-thumb {
background-color: var(--light-primary-color) !important;
}

.light-sheet-thumb::-webkit-scrollbar-thumb:hover {
background-color: var(--light-tertiary-color) !important;
}


/* Dark: dark */
.dark-sheet,
.dark-sheet-dditem,
.dark-sheet textarea::-webkit-input-placeholder {
background-color: var(--dark-primary-color) !important;
color: var(--dark-secondary-color) !important;
caret-color: var(--dark-secondary-color);
}

.dark-sheet-select::selection {
color: var(--dark-primary-color);
background-color: var(--dark-secondary-color);
}

.dark-sheet-ddmenu,
.dark-sheet-thumb::-webkit-scrollbar-thumb {
/* background-color: var(--dark-secondary-color) !important; */
background-color: var(--dark-primary-color) !important;
}

.dark-sheet-thumb::-webkit-scrollbar-thumb:hover {
background-color: var(--dark-tertiary-color) !important;
}

/* Blue: blue */
.blue-sheet,
.blue-sheet-dditem,
.blue-sheet textarea::-webkit-input-placeholder {
background-color: var(--blue-primary-color) !important;
color: var(--blue-secondary-color) !important;
caret-color: var(--blue-secondary-color);
}

.blue-sheet-select::selection {
color: var(--blue-primary-color);
background-color: var(--blue-secondary-color);
}

.blue-sheet-ddmenu,
.blue-sheet-thumb::-webkit-scrollbar-thumb {
background-color: var(--blue-primary-color) !important;
}

.blue-sheet-thumb::-webkit-scrollbar-thumb:hover {
background-color: var(--blue-tertiary-color) !important;
}

/* Pink: pink */
.pink-sheet,
.pink-sheet-dditem,
.pink-sheet textarea::-webkit-input-placeholder {
background-color: var(--pink-primary-color) !important;
color: var(--pink-secondary-color) !important;
caret-color: var(--pink-secondary-color);
}

.pink-sheet-select::selection {
color: var(--pink-primary-color);
background-color: var(--pink-secondary-color);
}

.pink-sheet-ddmenu,
.pink-sheet-thumb::-webkit-scrollbar-thumb {
background-color: var(--pink-primary-color) !important;
}

.pink-sheet-thumb::-webkit-scrollbar-thumb:hover {
background-color: var(--pink-tertiary-color) !important;
}

/* Darker */
.darker-sheet,
.darker-sheet-dditem,
.darker-sheet textarea::-webkit-input-placeholder {
background-color: var(--darker-primary-color) !important;
color: var(--darker-secondary-color) !important;
caret-color: var(--darker-secondary-color);
}

.darker-sheet-select::selection {
color: var(--darker-primary-color);
background-color: var(--darker-secondary-color);
}

.darker-sheet-ddmenu,
.darker-sheet-thumb::-webkit-scrollbar-thumb {
background-color: var(--darker-primary-color) !important;
}

.darker-sheet-thumb::-webkit-scrollbar-thumb:hover {
background-color: var(--darker-tertiary-color) !important;
}

/* Yellow: yllw-wtrmln */
.yllw-wtrmln-sheet,
.yllw-wtrmln-sheet-dditem,
.yllw-wtrmln-sheet textarea::-webkit-input-placeholder {
background-color: var(--yllw-wtrmln-primary-color) !important;
color: var(--yllw-wtrmln-secondary-color) !important;
caret-color: var(--yllw-wtrmln-secondary-color);
}

.yllw-wtrmln-sheet-select::selection {
color: var(--yllw-wtrmln-primary-color);
background-color: var(--yllw-wtrmln-secondary-color);
}

.yllw-wtrmln-sheet-ddmenu,
.yllw-wtrmln-sheet-thumb::-webkit-scrollbar-thumb {
background-color: var(--yllw-wtrmln-primary-color) !important;
}

.yllw-wtrmln-sheet-thumb::-webkit-scrollbar-thumb:hover {
background-color: var(--yllw-wtrmln-tertiary-color) !important;
}

/* Crispy Sky: crsp-sky */
.crsp-sky-sheet,
.crsp-sky-sheet-dditem,
.crsp-sky-sheet textarea::-webkit-input-placeholder {
background-color: var(--crsp-sky-primary-color) !important;
color: var(--crsp-sky-secondary-color) !important;
caret-color: var(--crsp-sky-secondary-color);
}

.crsp-sky-sheet-select::selection {
color: var(--crsp-sky-primary-color);
background-color: var(--crsp-sky-secondary-color);
}

.crsp-sky-sheet-ddmenu,
.crsp-sky-sheet-thumb::-webkit-scrollbar-thumb {
background-color: var(--crsp-sky-primary-color) !important;
}

.crsp-sky-sheet-thumb::-webkit-scrollbar-thumb:hover {
background-color: var(--crsp-sky-tertiary-color) !important;
}

/* Light Olive: lght-olv */
.lght-olv-sheet,
.lght-olv-sheet-dditem,
.lght-olv-sheet textarea::-webkit-input-placeholder {
background-color: var(--lght-olv-primary-color) !important;
color: var(--lght-olv-secondary-color) !important;
caret-color: var(--lght-olv-secondary-color);
}

.lght-olv-sheet-select::selection {
color: var(--lght-olv-primary-color);
background-color: var(--lght-olv-secondary-color);
}

.lght-olv-sheet-ddmenu,
.lght-olv-sheet-thumb::-webkit-scrollbar-thumb {
background-color: var(--lght-olv-primary-color) !important;
}

.lght-olv-sheet-thumb::-webkit-scrollbar-thumb:hover {
background-color: var(--lght-olv-tertiary-color) !important;
}

/* Halloween: hllwn */
.hllwn-sheet,
.hllwn-sheet-dditem,
.hllwn-sheet textarea::-webkit-input-placeholder {
background-color: var(--hllwn-primary-color) !important;
color: var(--hllwn-secondary-color) !important;
caret-color: var(--hllwn-secondary-color);
}

.hllwn-sheet-select::selection {
color: var(--hllwn-primary-color);
background-color: var(--hllwn-secondary-color);
}

.hllwn-sheet-ddmenu,
.hllwn-sheet-thumb::-webkit-scrollbar-thumb {
background-color: var(--hllwn-primary-color) !important;
}

.hllwn-sheet-thumb::-webkit-scrollbar-thumb:hover {
background-color: var(--hllwn-tertiary-color) !important;
}