.footer-text {
  appearance: none;
  background: transparent;
  outline: none;
  border: none;
  color: inherit;
  font-family: monospace;
  font-size: 1.1em;
  letter-spacing: 0.1em;
  text-align: center;
  width: 100%;
}

.fixed {
  min-height: 10%;
  padding-top: 1em;
  padding-bottom: 2em;
  position: fixed;
  width: 100%;
  bottom: 0;
}
