

.jot-container {
  height: 1000px;
  color: inherit;
  caret-color: inherit;
}

.text-container {
  height: 100%;
}

.any-textarea {
  appearance: none;
  height: 80vh;
  background: transparent;
  border: none;
  outline: none;
  font-family: monospace;
  font-size: 1.1em;
  letter-spacing: 0.1em;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 0em;
  padding-right: 0em;
  width: 100%;
  resize: none;
  color: inherit;
}

.side-textarea {
  overflow: hidden;
}

textarea.side-textarea::-webkit-scrollbar {
  display: none;
}

.center-textarea {
  white-space: nowrap;
  cursor: text;
}

textarea.center-textarea::-webkit-scrollbar {
  width: 0.8em;
  height: 0.6em;
}

textarea.center-textarea::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;
}

textarea.center-textarea::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

textarea.center-textarea::-webkit-scrollbar-corner {
  display: none;
}
